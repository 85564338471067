import React from 'react'
import { breakpoint, color } from '@configs/utilities'
import styled from 'styled-components'
import { HeadingH1, DescriptionDisplay1, RegilarText } from '@components/ui/typography/Typography'

const TextImageHero = ({
  label,
  title,
  description = [],
  image,
  button,
  textAlignSm = 'left',
  buttonAlignSm = 'left',
  reverse = false,
  section = false,
}) => {
  return (
    <Wrapper section={section} reverse={reverse}>
      <TextWrapper section={section} reverse={reverse} textAlignSm={textAlignSm}>
        {label ? <Label>{label}</Label> : null}
        <TitleWrapper>{section ? <p className="title">{title}</p> : <HeadingH1>{title}</HeadingH1>}</TitleWrapper>
        <DescriptionWrapper button={button}>
          {description.map((item, index) => {
            if (section) {
              return <RegilarText key={index}>{item}</RegilarText>
            }
            return <DescriptionDisplay1 key={index}>{item}</DescriptionDisplay1>
          })}
          {button && <ButtonWrapper buttonAlignSm={buttonAlignSm}>{button}</ButtonWrapper>}
        </DescriptionWrapper>
      </TextWrapper>
      <ImageWrapper reverse={reverse}>{image}</ImageWrapper>
    </Wrapper>
  )
}

export default TextImageHero

const Wrapper = styled.div`
  display: flex;
  column-gap: 50px;
  flex-direction: column;
  row-gap: 30px;

  @media screen and (min-width: ${breakpoint.md}) {
    padding: ${(props) => (props.section ? '0 40px' : '0')};
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    align-items: center;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    padding: ${(props) => (props.section ? '0 60px' : '0')};
  }
`

const TextWrapper = styled.div`
  flex-grow: 1;
  text-align: ${(props) => props.textAlignSm};

  @media screen and (min-width: ${breakpoint.lg}) {
    text-align: left;
    max-width: 50%;
    padding: ${(props) => (props.reverse ? '0 0 0 10%' : '0 10% 0 0')};
  }
`

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${color.ai};
  text-transform: uppercase;
  margin-bottom: 5px;
`

const TitleWrapper = styled.div`
  margin-bottom: 20px;

  .title {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 18px;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 26px;
    }
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => (props.button ? '25px' : '15px')};

  @media screen and (min-width: ${breakpoint.lg}) {
    row-gap: ${(props) => (props.button ? '50px' : '25px')};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.buttonAlignSm};

  @media screen and (min-width: ${breakpoint.lg}) {
    justify-content: start;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${breakpoint.md}) {
    justify-content: ${(props) => (props.reverse ? 'start' : 'end')};
    max-width: 80%;
    margin: 0 auto;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    flex-grow: 1;
    max-width: 50%;
  }
`
