import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import { HeadingH1, DescriptionDisplay1 } from '@components/ui/typography/Typography'
import ButtonLink from '@components/ui/buttons/ButtonLink'

const LandingHero = ({
  backgroundImage,
  illustration,
  landing,
  intl,
  alt,
  external,
  href,
  to,
  buttonText,
  explainText,
}) => {
  return (
    <Wrapper>
      <LandingHeroWrapper
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <Content>
          <HeadingH1>{intl.formatMessage({ id: landing.title })}</HeadingH1>
          <DescriptionDisplay1>{intl.formatMessage({ id: landing.text })}</DescriptionDisplay1>
          <ButtonLink
            external={external}
            href={href}
            to={to}
            buttonText={buttonText}
            explain
            explainText={explainText}
            mdfontsize={'20px'}
            align={'flex-start'}
          />
        </Content>
        <Illustration>
          <img src={illustration} alt={alt} />
        </Illustration>
      </LandingHeroWrapper>
    </Wrapper>
  )
}

export default LandingHero

const Wrapper = styled.div`
  max-width: 1380px;
  background: ${color.landingsBackground};
  border-radius: 20px;
  margin: 10px 10px 0;

  @media screen and (min-width: ${breakpoint.md}) {
    margin: 20px 20px 0;
  }
  @media screen and (min-width: ${breakpoint.lg}) {
    margin: 30px 30px 0;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    margin: 40px auto 0;
  }
`

const LandingHeroWrapper = styled.section`
  position: relative;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20px;

  @media screen and (min-width: ${breakpoint.lg}) {
    background-position: right;
    min-height: auto;
  }
`

const Illustration = styled.div`
  display: none;

  img {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    position: absolute;
    display: flex;
    width: 30%;
    bottom: -50px;
    right: 120px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  padding: 40px 20px 35vh;
  text-align: center;

  @media screen and (min-width: ${breakpoint.sm}) {
    width: 80%;
    padding: 40px 20px 40vh;
    margin: 0 auto;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    width: 80%;
    padding: 40px 20px 50vh;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    align-items: flex-start;
    width: 45%;
    padding: 40px 50px 15vh;
    margin: 0;
    text-align: left;
  }
`
