import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import testimonialQuote from '@assets/images/common/testimonial-quote.png'

const Testimonial = ({ text, signature }) => {
  return (
    <Wrapper>
      <img src={testimonialQuote} alt="quote" width={58} />
      <Content>
        <p className="text">{text}</p>
        <p className="signature">{signature}</p>
      </Content>
    </Wrapper>
  )
}

export default Testimonial

const Wrapper = styled.div`
  max-width: 974px;
  margin: 0 auto;
  display: flex;
  position: relative;
  background: #edf3fc;
  border-radius: 17px;
  padding: 62px 22px 24px 27px;

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 58px 23px 46px 46px;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    padding: 58px 31px 52px 63px;
  }

  img {
    position: absolute;
    top: -25px;
    left: 15%;

    @media screen and (min-width: ${breakpoint.sm}) {
      left: 7%;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  text-align: center;

  @media screen and (min-width: ${breakpoint.md}) {
    text-align: left;
  }

  .text {
    font-size: 16px;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 20px;
    }
  }

  .signature {
    font-size: 14px;
    color: ${color.blueDark};
    font-weight: 600;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 16px;
    }
  }
`
