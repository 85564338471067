import React from 'react'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import Excellent01 from '@components/pages/home/rating/Excellent01'

const ExcellentSection = ({
  sectionBackground,
  showWaves = false,
  wavesPosition = 'bottom',
  hidePaddingBottom = false,
}) => {
  return (
    <SectionWrapper
      sectionBackground={sectionBackground}
      showWaves={showWaves}
      wavesPosition={wavesPosition}
      hidePaddingBottom={hidePaddingBottom}
    >
      <Excellent01 />
    </SectionWrapper>
  )
}

export default ExcellentSection
