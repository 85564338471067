import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color } from '@configs/utilities'
import stars from '@assets/images/common/stars.png'

const Excellent01 = ({ intl }) => {
  return (
    <Wrapper>
      <span className="excellent">{intl.formatMessage({ id: 'lifetime-deal.hero.excellent' })}</span>
      <img src={stars} alt="stars" width={120} />
      <span className="based">
        ({intl.formatMessage({ id: 'lifetime-deal.hero.based' })}
        <a href="https://www.capterra.com/p/204342/IdeaBuddy/reviews/" target="_blank" rel="noopener noreferrer">
          {intl.formatMessage({ id: 'lifetime-deal.hero.capterra' })}
        </a>
        {intl.formatMessage({ id: 'lifetime-deal.hero.and' })}
        <a
          href="https://www.getapp.com/operations-management-software/a/ideabuddy/reviews/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({ id: 'lifetime-deal.hero.getApp' })}
        </a>
        {intl.formatMessage({ id: 'lifetime-deal.hero.reviews' })})
      </span>
    </Wrapper>
  )
}

export default injectIntl(Excellent01)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;

  .excellent {
    font-size: 20px;
    font-weight: 600;
  }

  img {
    margin: 0 15px;
  }
  .based {
    font-size: 13px;
    opacity: 0.5;

    a {
      margin: 0 3px;
      color: ${color.font};
      text-decoration: underline;
    }
  }
`
