import React from 'react'
import { HeadingH2, DescriptionDisplay2, HandwritingSubtitle } from './Typography'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'

const SectionHeader = ({ handwriting, title, description, titleMaxWidth = '70%', descriptionMaxWidth = '70%' }) => {
  return (
    <Wrapper>
      {handwriting && <HandwritingSubtitle>{handwriting}</HandwritingSubtitle>}
      <TitleWrapper titleMaxWidth={titleMaxWidth}>
        <HeadingH2>{title}</HeadingH2>
      </TitleWrapper>
      {description && (
        <DescriptionWrapper descriptionMaxWidth={descriptionMaxWidth}>
          <DescriptionDisplay2>{description}</DescriptionDisplay2>
        </DescriptionWrapper>
      )}
    </Wrapper>
  )
}

export default SectionHeader

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px 30px;
  row-gap: 20px;

  @media screen and (min-width: ${breakpoint.xl}) {
    row-gap: 30px;
    padding-bottom: 50px;
  }
`

const TitleWrapper = styled.div`
  @media screen and (min-width: ${breakpoint.md}) {
    max-width: ${(props) => props.titleMaxWidth};
  }
`

const DescriptionWrapper = styled.div`
  @media screen and (min-width: ${breakpoint.md}) {
    max-width: ${(props) => props.descriptionMaxWidth};
  }
`
