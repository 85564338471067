import React from 'react'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import Testimonial from '@components/common/Testimonial'

const TestimonialsSection = ({ text, signature }) => {
  return (
    <SectionWrapper>
      <Testimonial text={text} signature={signature} />
    </SectionWrapper>
  )
}

export default TestimonialsSection
