import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { graphql } from 'gatsby'
import Layout from '@components/layouts/default/Layout'
import LandingHeroSection from '@sections/landings/LandingHeroSection'
import ExcellentSection from '@sections/common/ExcellentSection'
import TestimonialSection from '@sections/common/TestimonialSection'
import { color } from '@configs/utilities'
import AdvertisementSection from '@sections/common/AdvertisementSection'
import BenefitsSection from '@sections/home/BenefitsSection'
import ExplainSection from '@sections/features/ExplainSection'
import ReadySection from '@sections/common/ReadySection'
import { generateAppLink } from '@configs/helpers'

export const query = graphql`
  query ($slug: String!) {
    solutionsJson(slug: { eq: $slug }) {
      slug
      hero {
        openModal
        trigger
        title
        text
        background {
          childImageSharp {
            original {
              src
            }
          }
        }
        illustration {
          childImageSharp {
            original {
              src
            }
          }
        }
        button {
          goToApp
          path
          text
        }
      }
      explainTitle
      explainDescription
      explain {
        reverse
        text
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              breakpoints: [320, 768, 1380]
            )
          }
        }
      }
      testimonial {
        signature
        text
      }
      explain03 {
        reverse
        text
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              breakpoints: [320, 768, 1380]
            )
          }
        }
      }
      explainSecond {
        reverse
        text
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              breakpoints: [320, 768, 1380]
            )
          }
        }
      }
    }
  }
`

const Solutions = ({ data, intl }) => {
  const solution = data.solutionsJson

  const backgroundImage = data.solutionsJson.hero.background.childImageSharp.original.src
  const illustrationImage = data.solutionsJson.hero.illustration.childImageSharp.original.src

  return (
    <Layout pageName={`solution-${solution.slug}`}>
      <LandingHeroSection
        backgroundImage={backgroundImage}
        illustration={illustrationImage}
        intl={intl}
        landing={solution.hero}
        external={solution.hero.button.goToApp}
        href={generateAppLink(solution.hero.button.path, intl.locale)}
        to={solution.hero.button.path}
        buttonText={intl.formatMessage({ id: solution.hero.button.text })}
        alt={solution.slug}
      />
      <ExcellentSection hidePaddingBottom />
      <ExplainSection feature={solution} explain={solution.explain} intl={intl} />
      <TestimonialSection
        text={intl.formatMessage({ id: solution.testimonial.text })}
        signature={intl.formatMessage({ id: solution.testimonial.signature })}
      />
      <ExplainSection showHeader={false} explain={[solution.explain03]} intl={intl} />
      <AdvertisementSection
        componentBackground={color.blueDark}
        titleText={intl.formatMessage({ id: 'home.businessPlanning.title' })}
        subtitleText={intl.formatMessage({ id: 'home.businessPlanning.subtitle' })}
        to="/pricing"
        // external
        // href={generateAppLink('signup', intl.locale)}
        buttonBackground={color.yellow}
        buttonColor={color.white}
        buttonText={intl.formatMessage({ id: 'buttons.getStarted' })}
      />
      <ExplainSection showHeader={false} explain={solution.explainSecond} intl={intl} />
      <BenefitsSection />
      <ReadySection
        sectionBackground={color.blueLight}
        title={intl.formatMessage({ id: 'home.readyToStartYourAdventure.title' })}
        description={intl.formatMessage({ id: 'home.readyToStartYourAdventure.description' })}
        buttonText={intl.formatMessage({ id: 'buttons.getStarted' })}
        to="/pricing"
        // external
        // href={generateAppLink('signup', intl.locale)}
        explain
        explainText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.explainText' })}
      />
    </Layout>
  )
}

export default injectIntl(Solutions)
