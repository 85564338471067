import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import BenefitsCard from '@components/pages/home/benefitsCard/BenefitsCard'

const BenefitsSection = ({ intl, sectionBackground }) => {
  const data = useStaticQuery(graphql`
    query {
      allBenefitsCardsJson {
        edges {
          node {
            headImage {
              childImageSharp {
                gatsbyImageData(width: 234, placeholder: BLURRED, formats: [AUTO, WEBP], breakpoints: [320, 768, 1380])
              }
            }
            head
            text
          }
        }
      }
    }
  `)
  return (
    <SectionWrapper sectionBackground={sectionBackground}>
      <CardsWrapper>
        {data.allBenefitsCardsJson.edges.map((item, index) => {
          return <BenefitsCard key={index} item={item} intl={intl} />
        })}
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(BenefitsSection)

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    justify-content: space-between;
  }
`
